import { Col, Container, Image, Row } from "react-bootstrap"
import VisionImage from '../assets/Wash Innovation Hub Vision.png'
const Vision = () => {
    return (
        <section className="vision">
            <Container>
                <Row>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="vision-image">
                            <Image src={VisionImage} alt="vision image" title="vision image" className="w-100" />
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12" className="self-center">
                        <div className="vision-content">
                            <h3 className="vision-title">VISION</h3>
                            <p>To transform Telangana into a lighthouse <br/>
                            for <strong>WASH</strong> innovations to enable India <br/>
                            achieve <strong>SDGs</strong></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Vision;