import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import MenuBar from './components/Menu';
// import InkwashTrustedBy from './components/InkwashTrustedBy';
import Footer from './components/Footer';
import Copyright from './components/Copyright';
import { StickyIcons } from './components/StickyIcons';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import ContactUs from './pages/ContactUs';
import KnowledgePartners from './pages/KnowledgePartners';
import Videos from './pages/Videos';
import AboutUsPage from './pages/AboutUs';
import ServicesPage from './pages/ServicesPage';
import ComingSoon from './pages/ComingSoon';
import ReactGA from "react-ga";
const Routing = () => {
  return (
    <Router>
      <MenuBar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route exact path="/knowledge-partner" component={KnowledgePartners} />
        <Route exact path='/videos' component={Videos} />
        <Route exact path='/about-us' component={AboutUsPage} />
        <Route exact path='/services' component={ServicesPage} />
        <Route exact path='/coming-soon' component={ComingSoon} />
      </Switch>
      {/* <InkwashTrustedBy /> */}
      <Footer />
      <Copyright />
      <StickyIcons />
      
    </Router>
  )
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Routing />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
