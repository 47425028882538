import { Container,Row,Col,Button } from 'react-bootstrap';
const VideoSectionBlock = () => {
    return (
        <>
        <section className="video-section-block">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12" className='text-center'>
                       <h4 className="section-title">VIDEOS</h4>
                       <video
                            playsinline
                            controls
                            src="Wash hub 2021 video.mp4"
                            className="w-80 mt-5"
                        />
                        <div className="button mt-5">
                            <a href="https://inkwash.washinnovationhub.in/inkwash20-videos/" target="_blank" className="btn btn-primary">VIEW ALL VIDEOS</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    )
}
export default VideoSectionBlock;