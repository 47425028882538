/* eslint-disable react/jsx-no-target-blank */
import { Button, Col, Container, Image, Row,Carousel } from "react-bootstrap"
import BannerImage from '../assets/header_image.png'
const BannerBlock = () => {
    return(
        <Carousel fade>
            <Carousel.Item>
                <section className="banner-block">
                    <Container>
                        <Row>
                            <Col md="7" lg="7" sm="7" xs="12" className="self-center">
                                <h3 className="sub-title">Promoting innovations in</h3>
                                <h1 className="banner-title">Water, Sanitation & Hygiene (WASH)
                                to support the achievement of the
                                Sustainable Development Goals</h1>
                                <div className="buttons mt-5">
                                <a href="https://inkwash.washinnovationhub.in/inkwash3/#event-register" target="_blank" className="btn btn-primary">PARTNER WITH US</a>
                                </div>
                            </Col>
                            <Col md="5" lg="5" sm="5" xs="12">
                                <div className="banner-image">
                                    <Image src={BannerImage} alt="Water, Sanitation & Hygiene (WASH)" title="Water, Sanitation & Hygiene (WASH)" className="w-100" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Carousel.Item>
            {/* <Carousel.Item>
                <section className="banner-block banner-2">
                    <Container>
                        <Row>
                            <Col md="12" lg="12" sm="12" xs="12" className="self-center">
                                <h3 className="sub-title">ICICI Bank Towers Financial District, Nanakaramguda - 05-06 May, 2022</h3>
                                <h1 className="banner-title">World's Largest Platform for <br/> Wash Innovations</h1>
                                <div className="buttons mt-5 inkwash-content">
                                    <a href="https://inkwash.washinnovationhub.in/inkwash3/#event-register" target="_blank" className="btn btn-primary">REGISTER</a>
                                    <a href="https://inkwash.washinnovationhub.in/inkwash3/#event-register" target="_blank" className="btn btn-primary btn-sponsper">SPONSOR</a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
    </Carousel.Item> */}
        </Carousel>
        
    )
}
export default BannerBlock;