/* eslint-disable react/jsx-no-target-blank */
import { Col, Container, Row, Button, Image } from "react-bootstrap"
import PartnerImage from '../assets/WIH_PartnerWithUs_Vector_Image.png'
const PartnerWithUs = () => {
    return(
        <section className="partner-with-us">
            <Container>
                <Row className="relative-position">
                    <Col md="7" lg="7" sm="7" xs="12" className="self-center">
                       <h4 className="section-title">PARTNER WITH US</h4>
                       <h5 className="section-sub-title">Collaborate for IMPACT</h5>
                       <p className="partner-content">We invite start-ups and innovators in <strong>WASH</strong> to
                        join the hub and avail its valuable services. We also
                        invite mentors, funders, development agencies,
                        cities, and other stakeholders forming the <strong>WASH</strong>
                        innovation ecosystem to partner with <strong>WIH</strong> and
                        contribute to nation building</p>
                        <div className="button mt-5">
                            <a href="https://inkwash.washinnovationhub.in/inkwash3/#event-register" target="_blank" className="btn btn-primary">PARTNER WITH US</a>
                        </div>
                    </Col>
                    <Col md="5" lg="5" sm="5" xs="12">
                        <div className="partner-image">
                            <Image src={PartnerImage} alt="PartnerImage" title="PartnerImage" className="w-100" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default PartnerWithUs;