import { Col, Container, Image, Row } from "react-bootstrap"
import Service1 from '../assets/icons_WIH_Services-01.png'
import Service2 from '../assets/icons_WIH_Services-02.png'
import Service3 from '../assets/icons_WIH_Services-03.png'
import Service4 from '../assets/icons_WIH_Services-04.png'
import Service5 from '../assets/icons_WIH_Services-05.png'
import Service6 from '../assets/icons_WIH_Services-06.png'
import Service7 from '../assets/icons_WIH_Services-07.png'
import Service8 from '../assets/icons_WIH_Services-08.png'
const Services = () => {
    return(
        <section className="services" id="services">
            <Container>
                <div className="section-title text-center">SERVICES</div>
                <Row className="mt-3 justify-content-center">
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="service-block">
                            <div className="service-icon">
                                <Image src={Service2} alt="Service 2" title="Service 2" />
                            </div>
                            <h5 className="service-title">Idea sourcing</h5>
                            <p>Events and resources
                            designed to promote the
                            creation and discovery of
                            sanitation innovation</p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="service-block">
                            <div className="service-icon">
                                <Image src={Service3} alt="Service 3" title="Service 3" />
                            </div>
                            <h5 className="service-title">Incubation</h5>
                            <p>Support and resources
                            for early-stage innovators as
                            they develop innovations
                            into startups</p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="service-block">
                            <div className="service-icon">
                                <Image src={Service4} alt="Service 4" title="Service 4" />
                            </div>
                            <h5 className="service-title">Design/ prototype</h5>
                            <p>Tools and expertise that
                            assist businesses to turn
                            a promising idea into a
                            functional product</p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="service-block">
                            <div className="service-icon">
                                <Image src={Service5} alt="Service 5" title="Service 5" />
                            </div>
                            <h5 className="service-title">Piloting/ scaling up</h5>
                            <p>Enabling sanitation businesses with a proven business model to rapidly
                             expand their services to additional markets</p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="service-block">
                            <div className="service-icon">
                                <Image src={Service6} alt="Service 6" title="Service 6" />
                            </div>
                            <h5 className="service-title">Financing</h5>
                            <p>Empowering sanitation
                            entrepreneurs to acquire
                            the financial resources
                            necessary to expand their
                            businesses</p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="service-block">
                            <div className="service-icon">
                                <Image src={Service7} alt="Service 7" title="Service 7" />
                            </div>
                            <h5 className="service-title">Knowledge sharing</h5>
                            <p>Facilitation of information exchange through conferences, expositions, and online resource</p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="service-block">
                            <div className="service-icon">
                                <Image src={Service8} alt="Service 8" title="Service 8" />
                            </div>
                            <h5 className="service-title">Capacity building/advocacy</h5>
                            <p>Workshops and trainings to educate ecosystem actors and improve monitoring; policy advocacy and standard setting for industry</p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="service-block">
                            <div className="service-icon">
                                <Image src={Service1} alt="Service 1" title="Service 1" />
                            </div>
                            <h5 className="service-title">Scientific research</h5>
                            <p>Backing for academic and
                                scientific research to
                                discover new, innovative
                                sanitation technologies
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Services;