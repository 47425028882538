import { useEffect, useState } from "react";
import { Container, Row,Col } from "react-bootstrap"
import { VideosFetch } from "../../actions/VideosFetch";

const VideoResources = () => {
    const [videoData,setVideoData]=useState([{}])
    useEffect(()=>{
        VideosFetch().then((res)=>{
            console.log(res.data.items);
            
        })
    })
    return(
        <section className="resources">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default VideoResources;