import { Container, Row, Col } from "react-bootstrap"
import Services from "../components/Services";
import ReactGA from "react-ga";
import { useEffect } from "react"
const ServicesPage = () => {
    useEffect(()=> {
        ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
        ReactGA.pageview(window.location.pathname);
    },[]);
    return(
        <>
            <section className="inner-section">
                <Container>
                    <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                            <h4 className="section-hub-sub-title">Services</h4>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Services />
        </>
    )
}
export default ServicesPage;