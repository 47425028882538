import { useEffect, useState } from "react"
import AboutInkwash from "../components/AboutInkwash"
import AboutUs from "../components/AboutUs"
import AssociatedWithUs from "../components/AssociatedWithUs"
import BannerBlock from "../components/Banner"
import Innovator from "../components/Innovator"
import PartnerWithUs from "../components/PartnerWithUs"
import { SanitationInnovator } from "../components/SanitationInnovator"
import Services from "../components/Services"
import VideoSectionBlock from "../components/VideoSectionBlock"
import Vision from "../components/Vision"
import WhatWeAre from "../components/WhatWeAre"
import ReactGA from "react-ga";
import { Image, Modal } from "react-bootstrap"
import GoaLogo from '../assets/InkWash_Goa_Logo.svg';

export const HomePage = () => {
    const [showpopUp, setShowPopup] = useState(false);
    const handleClose = () => setShowPopup(false);
  // eslint-disable-next-line no-unused-vars
  const handleShow = () => setShowPopup(true);
    useEffect(()=> {
        ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
        ReactGA.pageview(window.location.pathname);
        console.log(ReactGA);
    },[]);
    return(
        <>
        <BannerBlock />
        <SanitationInnovator />
        <AboutUs />
        <Vision />
        <WhatWeAre />
        <Services />
        <AboutInkwash />
        <Innovator />
        <AssociatedWithUs />
        <PartnerWithUs />
        <VideoSectionBlock />
        <Modal show={showpopUp} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Image src={GoaLogo} alt="Inkwash goa logo" title="inkwash goa logo" className='w-100' />
                <div className="button mt-5 text-center">
                        <a href="https://washinnovationhub.in/inkwash-goa/" target="_blank" className="btn btn-primary">Register Now</a>
                    </div>
            </Modal.Body>
            </Modal>
        </>
        
    )
}