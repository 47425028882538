import axios from "axios"

export const ContactForm = (data) => {
    return new Promise((resolve,reject)=>{
        axios.post(`${process.env.PUBLIC_URL}/apis/sponsper-form.php`,{
            name: data.name,
            email: data.email,
            mobileNumber: data.mobileNumber,
            website: data.website,
            comments: data.comments
        }).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}