/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Container, Row, Col } from "react-bootstrap"
import ReactGA from "react-ga";
import { useEffect } from "react"
const ComingSoon = () => {
    useEffect(()=> {
        console.log(process.env.REACT_APP_TRACKING_ID)
        ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
        ReactGA.pageview(window.location.pathname);
    },[]);
    return(
        <>
            <section className="coming-soon-block">
                <Container>
                    <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                            <h4 className="section-title">Great Things <br/> Coming Soon</h4>
                            <div className="button mt-5">
                                <a href={`${process.env.PUBLIC_URL}/contact-us`} target="_blank" className="btn btn-primary">CONTACT US</a>
                            </div>
                            <h3 className="mt-5">
                                <a href="">Facebook </a> | <a href="">Twitter </a> | <a href="">Youtube </a>
                            </h3>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default ComingSoon;