import { Button, Col, Container, Image, Row } from "react-bootstrap"
import InkwashLogo from '../assets/inkwash_logos_with_TM-03.png'
const AboutInkwash = () => {
    return (
        <section className="about-inkwash">
            <Container>
                <div className="about-inkwash-header text-center">
                    <h4 className="sub-caption">Ink@Wash</h4>
                    <h2 className="section-about-title">A Go-to-place for Wash Innovations in India</h2>
                </div>
                <Row className="mt-7">
                    <Col md="5" lg="5" sm="5" xs="12">
                        <div className="inkwash-image">
                            <Image src={InkwashLogo} alt="InkwashLogo" title="InkwashLogo" className="w-100" />
                        </div>
                    </Col>
                    <Col md="7" lg="7" sm="7" xs="12" className="self-center">
                        <div className="inkwash-content">
                            {/* <p>The Government of Telangana (GoTS) has prioritized <strong>Water, Sanitation and Hygiene (WASH)</strong> and is deeply committed in achieving the Sustainable Development Goal (SDG) targets well ahead of the globally agreed timelines. In line with this commitment, the Municipal Administration and Urban Development Department (MAUD), GoTS in partnership with the Administrative Staff College of India (ASCI) has established the <strong>WASH Innovation Hub (WIH)</strong> to promote innovations in water, sanitation and hygiene sectors of India. WIH is an ecosystem to continuously identify and develop innovative WASH solutions. It aims to build the capacities of municipalities and other stakeholders for implementing and sustaining these solutions.</p>
                            */}
                            <p>INK@WASH is a unique platform under WIH. It aims to promote innovations in <strong>WASH</strong> through collaboration and partnerships between start-ups/innovators, state/city goverments, mentors, academic institutions, non-profits and funders. Launched in 2019, INK@WASH has conducted several activities and currently has more than <strong>600 innovations and start-ups</strong> under its fold.</p>
                            <p>Launched in 2019, in a short span of time INK@WASH emerged as a successful platform where a number of new ideas are exchanged, developed and promoted. Many State and City governments are benefiting from the collective knowledge of the innovators/startups being curated by ASCI. It has successfully conducted two seasons of INK@WASH Talks. In the first year the focus was across the WASH space and during the second year the summit focused on circular economy and innovations in plastic waste management.</p>
                            <p>During both the summits, more than <strong>70</strong> promising innovations from <strong>350+</strong> ideas were selected using a robust process and showcased extensively. Detailed documentation of these innovations were complied in form of a compendium and disseminated widely.</p>
                            <div className="know-button">
                                <Button className="btn btn-secondary" onClick={()=>{window.open('https://inkwash.washinnovationhub.in/','__blank')}}>KNOW MORE</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default AboutInkwash;