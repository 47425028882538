import { Container} from "react-bootstrap"

const Innovator = () => {
    const windowPopupOpen = () =>{
        window.location.href=`${process.env.PUBLIC_URL}/coming-soon`
    }
    return(
        <section className="innovator">
            <Container>
                <h4 className="section-title text-center">MEET OUR INNOVATOR</h4>
                <div className="flex-align">
                    <label className="innovator-label" onClick={windowPopupOpen}>Water Quality</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Water Management</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Water Conservation</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Rain Water Harvesting</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Water Body Rejuvenation</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Used Water Treatment Recycle & Reuse</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Hygiene Product</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Faecal Sludge and Septage Management</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Worker Safety and Social Inclusion</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>E-Waste</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Sanitation Facilities and Service</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Solid Waste Management</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Plastic Waste Management</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Legacy Waste</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Biomedical Waste</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Behaviour Change and Communication</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>ICT Solutions</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Design thinking</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Smart Metering</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Data Analytics</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>C&D Waste</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Biomining & Remediation</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Gender Mainstreaming</label>
                    <label className="innovator-label" onClick={windowPopupOpen}>Others</label>
                </div>
            </Container>
        </section>
    )
}
export default Innovator;