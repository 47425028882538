/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Row,Col, Image } from "react-bootstrap"
import Facebook from '../assets/icons-01-Facebook.svg'
import Twitter from '../assets/icons-02-Twitter.svg'
import Youtube from '../assets/icon-youtube-01.svg'
const Copyright = () => {
    return(
        <section className="copyright">
            <Container>
                <Row>
                    <Col md="7" lg="7" sm="7" xs="12">
                        <h4 className="copyright-title"><a href={`${process.env.PUBLIC_URL}`}>Home</a> | <a href={`${process.env.PUBLIC_URL}#aboutUs`}>About Us</a> | <a href={`${process.env.PUBLIC_URL}#services`}>Services</a> | <a href="https://inkwash.washinnovationhub.in/" target="_blank">Inkwash</a> | <a href={`${process.env.PUBLIC_URL}/knowledge-partner`}>Resources</a> | <a href={`${process.env.PUBLIC_URL}/contact-us`}>Contact us</a></h4>
                        <h4 className="copyright-title">Copyright © 2022. All rights reserved</h4>
                    </Col>
                    <Col md="5" lg="5" sm="5" xs="12" className="self-center">
                        <p className="social-icons">
                            <Image src={Facebook} alt="Facebook" title="Facebook" />
                            <Image src={Twitter} alt="Twitter" title="Twitter" />
                            <Image src={Youtube} alt="Youtube" title="Youtube" />
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Copyright;