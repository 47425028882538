/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Container, Row, Col,Accordion, Button, Image } from "react-bootstrap"
import BookOfInnovation2022 from '../../assets/resources/2022/Book-of-Innovations-2022_Page_001.jpg';
import Catalogueofinnovations2022 from '../../assets/resources/2022/Catalogue-of-innovations-1_Page_001.jpg';
import Agenda from '../../assets/resources/2022/agenda_Page_01.jpg';
import BookofInnovations2021 from '../../assets/resources/2021/PWM-BookOfInnovations-2021_Page_001.jpg';
import CatalogueofInnovations2021 from '../../assets/resources/2021/Catalogue-of-Innovations-2021_Page_01.jpg';
import BookOfInnovation2020 from '../../assets/resources/2020/BookOfInnovations-Web_Page_001.jpg';
import CataloguefInnovation2020 from '../../assets/resources/2020/Catalogue_of_Innovations_Page_001.jpg';
import InnovationsinPTDesignOperations2020 from '../../assets/resources/2020/Innovations-in-PT-Design-Operations-June-2020_Page_01.jpg';
import Involvingshgs2020 from '../../assets/resources/2020/INVOLVING-SHGs-IN-OM-of-PTCT__Women-in-Sanitation_Page_01.jpg';
import PublicToiletInnovations2020 from '../../assets/resources/2020/Public-Toilet-Innovations_Page_001.jpg';
import WomenLeadersWaterEnvironmentalSanitation2020 from '../../assets/resources/2020/VOL-1-Women-Leaders-Water-Environmental-Sanitation-new-4_Page_001.jpg';
import ASCIJournalofManagement2020 from '../../assets/resources/2020/AJoM-49-March-2020_Page_001.jpg';
import InkwashProceedings2020 from '../../assets/resources/2020/INK@WASH-Proceedings_Page_01.jpg';
import InnovationsLeadingPractices from '../../assets/resources/2022/Innovations-LeadingPracticesUPDATED_Page_001.jpg';
const Resources  = () => {
    return (
        <section className="resources">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Year 2022</Accordion.Header>
                            <Accordion.Body>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={InnovationsLeadingPractices} alt="InnovationsLeadingPractices" title="InnovationsLeadingPractices" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Innovations Leading Practices</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={BookOfInnovation2022} alt="BookOfInnovation2022" title="BookOfInnovation2022" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Book of Innovations</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={Catalogueofinnovations2022} alt="Catalogueofinnovations2022" title="Catalogueofinnovations2022" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Catalogue of Innovations</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered end-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={Agenda} alt="Agenda" title="Agenda" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Agenda</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Year 2021</Accordion.Header>
                            <Accordion.Body>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={BookofInnovations2021} alt="Book of Innovations 2021" title="Book of Innovations 2021" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Book of Innovations</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered end-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={CatalogueofInnovations2021} alt="Catalogue of Innovations 2021" title="Catalogue of Innovations 2021" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Catalogue of Innovations</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Year 2020</Accordion.Header>
                            <Accordion.Body>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={BookOfInnovation2020} alt="Book of Innovations 2020" title="Book of Innovations 2020" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Book of Innovations</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={CataloguefInnovation2020} alt="Catalogue of Innovations 2020" title="Catalogue of Innovations 2020" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Catalogue of Innovations</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={InnovationsinPTDesignOperations2020} alt="Innovations in PT Design & Operations, June 2020" title="Innovations in PT Design & Operations, June 2020" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Innovations in PT Design & Operations, June 2020</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={Involvingshgs2020} alt="INVOLVING SHG’s IN O&M of PT,CT__Women in Sanitation 2020" title="INVOLVING SHG’s IN O&M of PT,CT__Women in Sanitation 2020" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">INVOLVING SHG’s IN O&M of PT,CT__Women in Sanitation</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={PublicToiletInnovations2020} alt="Public Toilet Innovations 2020" title="Public Toilet Innovations 2020" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Public Toilet Innovations</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={WomenLeadersWaterEnvironmentalSanitation2020} alt="Women Leaders Water & Environmental Sanitation – Vol 1 2020" title="Women Leaders Water & Environmental Sanitation – Vol 1 2020" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">Women Leaders Water & Environmental Sanitation – Vol 1</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={ASCIJournalofManagement2020} alt="ASCI Journal of Management 2020" title="ASCI Journal of Management 2020" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">ASCI Journal of Management</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                                <Row className="innovation-bordered end-bordered">
                                    <Col md="2" lg="2" sm="2" xs="12">
                                       <Image src={InkwashProceedings2020} alt="INK@WASH 1.0 Proceedings 2020" title="INK@WASH 1.0 Proceedings 2020" className="w-100" />
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <h3 className="book-title">INK@WASH 1.0 Proceedings</h3>
                                        <p className="mb-2"><strong>For further details, please contact :</strong></p>
                                        <p>Prof V. Srinivas Chary <br/>
                                        CEO, WASH Innovation Hub<br/>
                                        email: schary@asci.org.in<br/>
                                        Phone: 040 66534221</p>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Resources;