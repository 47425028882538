import { Col, Container, Image, Row} from "react-bootstrap"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import gilogo from '../assets/Sponspers/01-giz-logo.png'
import CiBcLogo from '../assets/Sponspers/02-CIBC-logo.png';
import Sustainability from '../assets/Sponspers/03-Sustainability-logo.png';
import Shrushti from '../assets/Sponspers/04-Shrushti-logo.png';
import ZyclonicBySCGC from '../assets/Sponspers/05-ZyclonicBySCGC-logo.png';
import BANKA_BIO from '../assets/Sponspers/06-BANKA_BIO-logo.png';
import Cube_Bio_Energy from '../assets/Sponspers/07-Cube_Bio_Energy-logo.png';
import Daiki_Axis_Inida from '../assets/Sponspers/08-Daiki_Axis_Inida-logo.png';
import Daynere_Engineering from '../assets/Sponspers/09-Daynere_Engineering-logo.png';
import Greenko_logo from '../assets/Sponspers/10-Greenko_logo.png';
import HappyTap_logo from '../assets/Sponspers/11-HappyTap_logo.png';
import Harshitha_logo from '../assets/Sponspers/12-Harshitha_logo.png';
import HLL_Lifecare_Limited_logo from '../assets/Sponspers/13-HLL_Lifecare_Limited_logo.png';
import Inidan_Oil from '../assets/Sponspers/14-Inidan_Oil-logo.png';
import KAMAvida from '../assets/Sponspers/15-KAM-Avida-logo.png';
import GHMC_Loo_Cafe from '../assets/Sponspers/16-GHMC_Loo_Cafe-logo.png';
import NCPE from '../assets/Sponspers/17-NCPE-logo.png';
import PriyaDhar from '../assets/Sponspers/18-PriyaDhar-logo.png';
import Suseela_Group from '../assets/Sponspers/19_Suseela_Group-logo.png';
import Water_Systems from '../assets/Sponspers/20-Water_Systems-logo.png';
import Covestro_logo from '../assets/Sponspers/21-Covestro_logo.png';
import nnovations_in_Public_System_logo from '../assets/Sponspers/22-Innovations_in_Public_System_logo.png';
import Induslnd_Bank_logo from '../assets/Sponspers/23_Induslnd_Bank_logo.png';
const AssociatedWithUs = () => {
    const responsive = {
        0:{
                items:1,
                nav:true
            },
            600:{
                items:3,
                nav:false
            },
            1000:{
                items:4,
                nav:true,
                loop:false
            }
    }
    const navTextArrows=[
        '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        '<i class="fa fa-angle-right" aria-hidden="true"></i>'
    ]
    return(
        <section className="bg-associated">
            <Container>
                <Row className="relative-position">
                    <Col md="12" lg="12" sm="12" xs="12" className="self-center text-center">
                       <h5 className="section-sub-title">Meet</h5>
                       <h4 className="section-title">Our partners </h4>
                    </Col>
                    <Col md="12" lg="12" sm="12" xs="12" className="mt-5">
                        <OwlCarousel className='owl-theme'  loop margin={30} nav responsive={responsive} navText={navTextArrows} navContainer=".custom-nav">
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={gilogo} alt="gilogo" title="gilogo" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={CiBcLogo} alt="CiBcLogo" title="CiBcLogo" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Sustainability} alt="Sustainability" title="Sustainability" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Shrushti} alt="Shrushti" title="Shrushti" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={ZyclonicBySCGC} alt="ZyclonicBySCGC" title="ZyclonicBySCGC" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={BANKA_BIO} alt="BANKA_BIO" title="BANKA_BIO" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Cube_Bio_Energy} alt="Cube_Bio_Energy" title="Cube_Bio_Energy" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Daiki_Axis_Inida} alt="Daiki_Axis_Inida" title="Daiki_Axis_Inida" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Daynere_Engineering} alt="Daynere_Engineering" title="Daynere_Engineering" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Greenko_logo} alt="Greenko_logo" title="Greenko_logo" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={HappyTap_logo} alt="HappyTap_logo" title="HappyTap_logo" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Harshitha_logo} alt="Harshitha_logo" title="Harshitha_logo" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={HLL_Lifecare_Limited_logo} alt="HLL_Lifecare_Limited_logo" title="HLL_Lifecare_Limited_logo" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Inidan_Oil} alt="Inidan_Oil" title="Inidan_Oil" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={KAMAvida} alt="KAMAvida" title="KAMAvida" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={GHMC_Loo_Cafe} alt="GHMC_Loo_Cafe" title="GHMC_Loo_Cafe" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={NCPE} alt="NCPE" title="NCPE" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={PriyaDhar} alt="PriyaDhar" title="PriyaDhar" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Suseela_Group} alt="Suseela_Group" title="Suseela_Group" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Water_Systems} alt="Water_Systems" title="Water_Systems" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Covestro_logo} alt="Covestro_logo" title="Covestro_logo" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={nnovations_in_Public_System_logo} alt="nnovations_in_Public_System_logo" title="nnovations_in_Public_System_logo" />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="item-block">
                                    <div className="text-center item-flex-center">
                                       <Image src={Induslnd_Bank_logo} alt="Induslnd_Bank_logo" title="Induslnd_Bank_logo" />
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                        <div className="owl-theme">
                            <div className="owl-controls">
                                <div className="custom-nav owl-nav"></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default AssociatedWithUs;