import { Container, Row, Col } from "react-bootstrap"
import Resources from "../components/knowledgepartner/Resources";
import ReactGA from "react-ga";
import { useEffect } from "react"
const KnowledgePartners = () => {
    useEffect(()=> {
        ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
        ReactGA.pageview(window.location.pathname);
    },[]);
    return(
        <>
            <section className="inner-section">
                <Container>
                    <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                            <h4 className="section-hub-sub-title">Knowledge Products</h4>
                            <h4 className="section-title">Platform For Innovators & Start-Ups</h4>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Resources />
        </>
    )
}
export default KnowledgePartners;