import { Container, Row,Col,Image } from "react-bootstrap"
import Values from '../assets/icon-WIH-WhatWeDo-Values.png'
import Personality from '../assets/icon-WIH-WhatWeDo-Personality.png'
import CoreAmbition from '../assets/icon-WIH-WhatWeDo-Ambition.png'
const WhatWeAre = () => {
    return (
        <section className="what-we-are">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h3 className="what-we-title">WHY ARE WE HERE</h3>
                        <p className="what-we-content">To give strength and scale to innovation
                        in WASH so that it is sustainable and inclusive</p>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="what-block">
                            <h4 className="why-we-are-title">Who are we <br/>here for?</h4>
                            <p>For cities that seek resilient solutions for their <strong>WASH</strong> infrastructure and for innovators who seek a capable,
                            nurturing ecosystem to create real impact in <strong>WASH</strong></p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="what-block">
                            <h4 className="why-we-are-title">What do we do and <br/>how we do IT?</h4>
                            <p>We provide a collaborative,
                            multi-disciplinary platform that
                            promotes research, assists in
                            capacity building and fosters
                            new ideas and helps them
                            reach fruition by providing incubation, prototyping and
                            up-scaling.</p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="what-block">
                            <h4 className="why-we-are-title">What makes us <br/>different?</h4>
                            <p>The ability to connect cities, innovators, mentors and funders
                            in a synergetic manner to
                            ensure that ideas thrive, reach
                            implementation and achieve
                            impact through true scalability</p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="what-block">
                            <h4 className="why-we-are-title">What do We Value <br/>the Most?</h4>
                            <p className="flex-align-block"> 
                                <span><Image src={Values} alt="Values" title="Values" /></span>Innovation <br/>Inclusivity <br/> Impact</p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="what-block">
                            <h4 className="why-we-are-title">What is <br/> Our Core Ambition?</h4>
                            <p className="flex-align-block"><span><Image src={CoreAmbition} alt="CoreAmbition" title="CoreAmbition" /></span>
                            <span>For Telangana and
                            India to become
                            a lighthouse to the
                            world in <strong>WASH</strong>
                            innovations.</span></p>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="what-block">
                            <h4 className="why-we-are-title">What's Our <br/> Personality?</h4>
                            <p className="flex-align-block"><span><Image src={Personality} alt="Personality" title="Personality" /></span>Problem-solving <br/> Empathetic <br/> Brave <br/> Collaborative.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default WhatWeAre;