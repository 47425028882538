import { Col, Container, Row,Image } from "react-bootstrap"
import Facebook from '../assets/icons-01-Facebook.svg'
import Twitter from '../assets/icons-02-Twitter.svg'
import Youtube from '../assets/icon-youtube-01.svg'
export const StickyIcons = () => {
    return(
        <section className="sticky-icons">
            <Container>
                <>
                    <>
                     
                        <p><Image src={Facebook} alt="Facebook" title="Facebook" /></p>
                        <p><Image src={Twitter} alt="Twitter" title="Twitter" /></p>
                        <p><Image src={Youtube} alt="Youtube" title="Youtube" /></p>
                    </>
                </>
            </Container>
        </section>
    )
}