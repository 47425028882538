import { Col, Container, Image, Row } from "react-bootstrap"
import AboutUsImage from '../assets/WIH_About_Section_Image.png'
const AboutUs = () => {
    return(
        <section className="about-us" id="aboutUs">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h4 className="sub-caption">About</h4>
                        <h2 className="section-about-title">WASH INNOVATION HUB</h2>
                        <p>Telangana, the youngest state of India, has rapidly emerged as the innovation capital of India. It has successfully built vibrant innovation ecosystems in various sectors and established T-Hub, Telangana State
                        Innovation Cell (TSIC), T-Works, RICH, We-Hub and WIH-Hub etc.,</p>
                        <p>Government of Telangana (GoTS) has prioritized Water Sanitation and Hygiene (WASH) and is deeply
                        committed to achieving the Sustainable Development Goal (SDG) targets well ahead of the globally
                        agreed timelines. In line with this commitment, the Municipal Administration and Urban Development Department (MA&UD), GoTS is pleased to announce the establishment of the WASH Innovation Hub (WIH), a game
                        changing initiative to promote innovations in water, sanitation, and hygiene sectors for India.
                        <br/>This is in partnership with Administrative Staff College of India (ASCI).</p>
                       <div className="about-us-image">
                           <Image src={AboutUsImage} alt="AboutUsImage" title="AboutUsImage" />
                       </div>
                       <p><i>“The WIH is an ecosystem to continuously identify and develop innovative WASH solutions and building
                            the capacities of municipalities and other stakeholders for implementing and sustaining these solutions.”</i></p>
                        <p className="highlighted-text">
                            <div className="highlighted-text-word">It is currently working with over <span>600 start-ups and innovators</span></div> to creatively solve problems confronting our cities and local bodies, on a mission mode.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default AboutUs;