import { useEffect, useState } from 'react';
import { Navbar, Container,NavDropdown,Nav, Image, Modal, Button } from 'react-bootstrap';
import Logo from '../assets/Logo_Wash_Innovation_Hub(WIH).png'
import GoaLogo from '../assets/InkWash_Goa_Logo.svg';
const MenuBar = () => {
    const [navBarPosition,setnavbarPosition]=useState('')
    useEffect(()=>{
        window.addEventListener("scroll", handleScroll);
    })
    const handleScroll = () =>{
        if (window.scrollY > 100) {
          setnavbarPosition('bg-scroll-active')
    } else {
        setnavbarPosition('')
    }
    }
    const [show, setShow] = useState(false);
    const showDropdown = (e)=>{
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }
    const [show1, setShow1] = useState(false);
    const show1Dropdown = (e)=>{
        setShow1(!show);
    }
    const hide1Dropdown = e => {
        setShow1(false);
    }
    const [showpopUp, setShowPopup] = useState(false);

  const handleClose = () => setShowPopup(false);
  const handleShow = () => setShowPopup(true);
    return(
        <>
            <section className="menu">
                <Navbar expand="lg" fixed='top' className={navBarPosition}>
                    <Container>
                        <Navbar.Brand href={`${process.env.PUBLIC_URL}`}>
                            <Image src={Logo} alt="Logo" title='Logo' />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
                        <Nav className="ml-auto">
                            <Nav.Link href={`${process.env.PUBLIC_URL}`}>Home</Nav.Link>
                            <Nav.Link href={`${process.env.PUBLIC_URL}/about-us`}>About Us</Nav.Link>
                            <Nav.Link href={`${process.env.PUBLIC_URL}/services`}>Services</Nav.Link>
                            <NavDropdown title="Ink@wash" id="navbarScrollingDropdown" show={show1}
                            onMouseEnter={show1Dropdown} 
                            onMouseLeave={hide1Dropdown}>
                                <NavDropdown.Item href="https://inkwash.washinnovationhub.in/inkwash3/" target="_blank">Ink@wash2022</NavDropdown.Item>
                                <NavDropdown.Item href="https://inkwash.washinnovationhub.in/inkwash-2021-summit/" target="_blank">Ink@wash2021</NavDropdown.Item>
                                <NavDropdown.Item href="https://inkwash.washinnovationhub.in/inkwash20-timeline/" target="_blank" className="border-bottom-none">Ink@wash2020</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="https://washinnovationhub.in/inkwash-goa/" target="_blank">Ink@wash Goa</Nav.Link>
                            <NavDropdown title="Resources" id="navbarScrollingDropdown" show={show}
                            onMouseEnter={showDropdown} 
                            onMouseLeave={hideDropdown}>
                                <NavDropdown.Item href={`${process.env.PUBLIC_URL}/knowledge-partner`}>Knowledge Products</NavDropdown.Item>
                                <NavDropdown.Item href="https://inkwash.washinnovationhub.in/inkwash20-videos/" target="_blank">Videos</NavDropdown.Item>
                                <NavDropdown.Item href="https://inkwash.washinnovationhub.in/gallery/" className="border-bottom-none" target="_blank">Pictures</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href={`${process.env.PUBLIC_URL}/contact-us`}>Contact us</Nav.Link>
                        </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </section>
            </>
    )
}
export default MenuBar;