import axios from "axios"
const url = `${process.env.REACT_APP_VIDEO_URL}?key=${process.env.REACT_APP_VIDEO_KEY_ID}&channelId=${process.env.REACT_APP_CHANNEL_ID}&order=date&maxResults=${process.env.REACT_APP_RESULTS}`
export const VideosFetch = () => {
    return new Promise((resolve,reject)=> {
        axios.get(url).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}