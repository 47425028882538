import { Col, Container, Row,Image } from "react-bootstrap"
import Logo from '../assets/Logo_Wash_Innovation_Hub(WIH).png'
import PhoneIcon from '../assets/icons-04.svg'
import MailIcon from '../assets/icons-03.svg'
import AsciLogo from '../assets/asci.png'
const Footer = () => {
    return(
        <section className="footer-section">
            <Container>
                <Row>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="footer-logo">
                            <Image src={Logo} alt="Logo" title='Logo' />
                        </div>
                        <p>A first of its kind <br/>
                        dedicated <strong>WASH</strong> innovation <br/>
                        eco-system</p>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <h4 className="footer-title">Contact Us</h4>
                        <p className="icon-alignment"><Image src={PhoneIcon} alt="phone icon" title="phone icon" /> <span>040 66534221</span></p>
                        <p className="icon-alignment"><Image src={MailIcon} alt="phone icon" title="phone icon" /> <span>schary@asci.org.in <br/>
                        asci@inkwash.in</span></p>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <h4 className="footer-title">Address</h4>
                        <div className="asci-logo">
                            <Image src={AsciLogo} alt="AsciLogo" title="AsciLogo" />
                        </div> 
                        <p>Administrative Staff College of India
                        Bella Vista Campus, Raj Bhavan Road,
                        Khairatabad, Hyderabad,
                        Telangana, India – 500082
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Footer;