import { Col, Container, Row, Image } from "react-bootstrap"
import WashInnovations from '../assets/icons/Wash Innovations.svg'
import InnovationsMentored from '../assets/icons/Innovations Mentored.svg'
import CitiesAcrossIndiaEngaged from '../assets/icons/Cities Across India Engaged.svg'
import Pilotssupported from '../assets/icons/Pilots supported.svg'
import Events from '../assets/icons/Events.svg'
import Peopleinteraction from '../assets/icons/People interaction.svg'
export const SanitationInnovator = () => {
    return(
        <section className="sanitatory-innovator">
            <Container>
                <Row>
                    <Col md="4" lg="4" sm="4" xs="6">
                        <div className="flex-box">
                            <Image src={WashInnovations} alt="WashInnovations" title="WashInnovations" />
                            <div>
                                <h4 className="innovator-title">600+</h4>
                                <span>WASH <br className="mobile-hide" />Innovations <br className="mobile-hide"/> Identified</span>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="6">
                        <div className="flex-box">
                            <Image src={InnovationsMentored} alt="WashInnovations" title="WashInnovations" />
                            <div>
                                <h4 className="innovator-title">244+</h4>
                                <span>Innovations <br className=""/>Mentored</span>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="6">
                        <div className="flex-box">
                            <Image src={CitiesAcrossIndiaEngaged} alt="CitiesAcrossIndiaEngaged" title="CitiesAcrossIndiaEngaged" />
                            <div>
                                <h4 className="innovator-title">55+</h4>
                                <span>Cities Across <br className=""/>India Engaged</span>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="6">
                        <div className="flex-box">
                            <Image src={Pilotssupported} alt="Pilotssupported" title="Pilotssupported" />
                            <div>
                                <h4 className="innovator-title">148+</h4>
                                <span>Pilots <br className=""/>Supported</span>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="6">
                        <div className="flex-box">
                            <Image src={Events} alt="Events" title="Events" />
                            <div>
                                <h4 className="innovator-title">48+</h4>
                                <span>Events <br className=""/>Conducted</span>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="6">
                        <div className="flex-box">
                            <Image src={Peopleinteraction} alt="Peopleinteraction" title="Peopleinteraction" />
                            <div>
                                <h4 className="innovator-title">1446+</h4>
                                <span>People <br className=""/>Interacted</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}