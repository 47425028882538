import { Col, Container, Row, Image, Form,Button,Alert } from "react-bootstrap"
import PhoneIcon from '../assets/icons-04.svg'
import MailIcon from '../assets/icons-03.svg'
import LocationIcon from '../assets/icon-address.svg'
import { useState } from "react";
import { ContactForm } from "../actions/ContactForm";
const ContactHubForm = () => {
    const [validated, setValidated] = useState(false);
    const [formHub,setFormHub]=useState({
        name: '',
        email: '',
        mobileNumber: '',
        website: '',
        comments: ''
    })
    const [enableForm,setEnableForm]=useState(false)
    const [responseMessage,setResponseMesage] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {
        event.preventDefault();
        if(formHub.mobileNumber.length!==10) {
            event.preventDefault();
            setEnableForm(true)
            event.stopPropagation();
        } else {
            ContactForm(formHub).then((res)=>{
                console.log(res.data)
              console.log(res)
              if(res.data.status==='404'){
                alert(res.data.message)
              }
              else {
                setFormHub({
                      ...formHub,
                      name: '',
                      email: '',
                      mobileNumber: '',
                      website: '',
                      comments: ''
                  })
                  setResponseMesage(true);
                  setValidated(false);
              }
            })
        }
    }
    setValidated(true);
  };
    return(
        <section className="contact-hub-form">
            <Container>
                <div className="about-inkwash-header text-center">
                    {/* <h2 className="section-about-title">we'd like</h2> */}
                    <h4 className="section-about-title">HERE’S HOW YOU CAN REACH US</h4>
                    <p>Registration, screening, press, media, sponsors, funders, donors and delegates can reach out at this address.</p>
                </div>
                <Row className="mt-7">
                    <Col md="5" lg="5" sm="5" xs="12">
                        <div className="address-block">
                            <div className="address-icon">
                                <Image src={LocationIcon} alt="LocationIcon" title="LocationIcon" />
                                <span>ADDRESS</span>
                            </div>
                            <p>Administrative Staff College of India <br/>
                                BellaVista, Raj Bhavan Road,<br/>
                                Khairatabad, Hyderabad – 500082,<br/>
                                India.</p>
                        </div>
                        <div className="address-block">
                            <div className="address-icon">
                                <Image src={PhoneIcon} alt="Phone Icon" title="Phone Icon" />
                                <span>PHONE</span>
                            </div>
                            <p>+91 9748676906 <br/>
                             +91 9000944923</p>
                        </div>
                        <div className="address-block">
                            <div className="address-icon">
                                <Image src={MailIcon} alt="EMAIL" title="EMAIL" />
                                <span>EMAIL</span>
                            </div>
                            <p>asci@inkwash.in <br/>
                            schary@asci.org.in</p>
                        </div>
                    </Col>
                    <Col md="7" lg="7" sm="7" xs="12">
                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="hub-form">
                            <h4 className="form-title">PLEASE FILL THE FORM</h4>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Name *" required onChange={e=>setFormHub({...formHub,name:e.target.value})} value={formHub.name} />
                                <Form.Control.Feedback type="invalid">
                                    Please Provide Name
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="number" placeholder="Mobile Number " className={enableForm?formHub.mobileNumber.length!==10?'invalid':'valid':''} onChange={e=>setFormHub({...formHub,mobileNumber:e.target.value})} value={formHub.mobileNumber} required />
                                {enableForm?formHub.mobileNumber.length!==10 ? <div className="feedBack-invalid">Please enter correct mobile number</div>  : null:null}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="email" placeholder="Email *" required onChange={e=>setFormHub({...formHub,email:e.target.value})} value={formHub.email} />
                                <Form.Control.Feedback type="invalid">
                                   Please Provide Email Id
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Website" onChange={e=>setFormHub({...formHub,website:e.target.value})} value={formHub.website} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control as="textarea" rows={3} placeholder="Tell us about you" onChange={e=>setFormHub({...formHub,comments:e.target.value})} value={formHub.comments} />
                            </Form.Group>
                            <div className="buttons text-center mt-3">
                               <Button type="submit" className="btn btn-primary">SEND MESSAGE</Button>
                            </div>
                            <Alert
                                variant="success"
                                className={responseMessage ? "d-block mt-3 mb-0" : "d-none"}
                                >
                                Thanks for contacting us. Our team will get back to you soon.
                            </Alert>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default ContactHubForm;