import { Container,Row,Col } from "react-bootstrap"
import ContactHubForm from "../components/ContactHubForm";
import ReactGA from "react-ga";
import { useEffect } from "react"
const ContactUs = () => {
    useEffect(()=> {
        ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
        ReactGA.pageview(window.location.pathname);
    },[]);
    return(
        <>
            <section className="inner-section">
                <Container>
                    <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                            <h4 className="section-hub-sub-title text-center">WE WOULD LOVE TO HEAR FROM YOU!</h4>
                            {/* <h4 className="section-title">We’d love to hear from you! Here’s how you can reach us.</h4>
                            */}
                        </Col>
                    </Row>
                </Container>
            </section>
            <ContactHubForm />
            <iframe title="Asci" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.9251137627375!2d78.45681931486037!3d17.415381106581044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97458d5d30b7%3A0x77afc2280d00853e!2sAdministrative%20Staff%20College%20of%20India!5e0!3m2!1sen!2sin!4v1651127589728!5m2!1sen!2sin" width="100%" height="550" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </>
        
    )
}
export default ContactUs;